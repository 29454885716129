import React from "react";
import * as prismic from "@prismicio/client";
import styles from "./CustomAccordion.module.css";
import { Box, Text } from "@chakra-ui/react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";

interface ICustomAccordionProps {
  FAQS: prismic.RichTextField[];
}

interface IFaqAnswer {
  heading: string;
  body: string;
}

interface IFaq {
  question: string;
  answer: IFaqAnswer[];
}

// type FaqItem = {
//   question: { type: string; text: string; spans: any[] }[];
//   answer: { type: string; text: string; spans: any[] }[];
// };

type FaqItem = {
  question: prismic.RichTextField;
  answer: prismic.RichTextField;
};

const CustomAccordion = ({ faqs }: { faqs: FaqItem[] }): JSX.Element => {
  return (
    <Accordion allowToggle>
      {faqs.map((faq, index) => (
        <AccordionItem border="unset" key={index}>
          <AccordionButton className={styles.accordionButton} _expanded={{ borderLeftColor: "#171717" }}>
            <Box as="span" flex="1" textAlign="left">
              {/* <h3>{faq.question[0].text}</h3> */}
              <h3>{prismic.asText(faq.question)}</h3>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            <PrismicRichText field={faq.answer} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default CustomAccordion;
