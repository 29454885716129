import { useEffect, useState } from "react";
import { createClient } from "@/prismicio";
import { SettingsDocument, FaqsDocumentData } from "../../prismicio-types";

/**
 * Custom hook for fetching Faqs
 */

const UseFaqs = (): { faqs: FaqsDocumentData | null; isLoading: boolean; error: Error | null } => {
  const [faqs, setFaqs] = useState<FaqsDocumentData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getFaqsData = async () => {
      try {
        const client = createClient();
        const faqsData = await client.getSingle("faqs");
        setFaqs(faqsData.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    getFaqsData();
  }, []);

  return { faqs, isLoading, error };
};

export default UseFaqs;
