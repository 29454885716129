import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import CustomAccordion from "@/components/Accordion/CustomAccordion";

import UseFaqs from "@/hooks/useFaqs";
import styles from "./Faqs.module.css";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

/**
 * Props for `Faqs`.
 */
export type FaqsProps = SliceComponentProps<Content.FaqsSlice>;

/**
 * Component for "Faqs" Slices.
 */
const Faqs = ({ slice }: FaqsProps): JSX.Element => {
  const { faqs, isLoading, error } = UseFaqs();

  return (
    <Box className={styles.faqs}>
      <FadeInWhenVisible>
        <Box className="container">
          <Flex className={styles.faqs__content}>
            <Box className={styles.faqs__left}>
              <Text
                textStyle="h2"
                as="h2"
                color="brand.dark"
                marginBottom="20px"
              >
                Frequently Asked Questions
              </Text>
            </Box>
            <Box className={styles.faqs__right}>
              {isLoading || error ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Flex>
              ) : (
                <>
                  {faqs?.faq_item?.length > 0 && (
                    <CustomAccordion faqs={faqs?.faq_item} />
                  )}
                </>
              )}
            </Box>
          </Flex>
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default Faqs;
